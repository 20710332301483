<template>
    <div>
        <b-navbar toggleable="xl" type="dark">
            <b-col cols="7">
                <b-navbar-brand href="/">
                    <img class="img-fluid" src="../assets/logo.svg">
                </b-navbar-brand>
            </b-col>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <b-nav-item href="/">Autopflege</b-nav-item>
                    <b-nav-item href="coupons">Gutscheine</b-nav-item>
                    <b-nav-item href="about">Über uns</b-nav-item>
                    <b-nav-item href="offer">Stellenangebote</b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
        <b-container fluid class="index-header">
            <b-row v-if="homePage">
                <b-col cols="12" class="text-center">
                    <h1>Glänzende Zukunft<br> für Ihr Auto!</h1>
                    <p>Erstklassige Aufbereitung und Reinigung<br> aller Fahrzeugtypen</p>
                    <!-- <form v-on:submit.prevent="searchCity">
                       <div class="input-group justify-content-center">
                           <input v-model="zipcode" type="text" class="start" placeholder="PLZ eingeben"/>
                           <div class="input-group-append">
                               <button type="submit" class="input-group-text" id="basic-addon2">
                                   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                     <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                   </svg>
                               </button>
                           </div>
                       </div>
                   </form> -->

                   <a @click="scrollToStart" class="button">Autopflege buchen</a>
                   <router-link to="coupons" class="button">Gutschein buchen</router-link>
                </b-col>
            </b-row>
            <b-row v-if="!homePage">
                <b-col cols="12" class="header">
                    &nbsp;
                </b-col>
            </b-row>
        </b-container>
        <b-container fluid class=" claim" v-if="homePage">
            <b-row>
                <b-col cols="12" class="text-center">
                    <p>Neu in Dresden im Autohaus Israel</p>
                </b-col>
            </b-row>
        </b-container>
        <b-container fluid class="seperator">
        </b-container>
    </div>
</template>
<script>

    import VueScrollTo from 'vue-scrollto';
    export default {
        name: 'Header',
        data() {
            return {
                zipcode: ''
            }
        },
        methods: {
            scrollToStart(){

                VueScrollTo.scrollTo('#start', 700, {offset: -40})
            },
            searchCity(){
                console.log(this.zipcode)
            }

        },
        computed: {
            homePage() {
                if(this.$route.path == "/" || this.$route.path == "/home" ) {
                    return true
                } else {
                    return false
                }
            }
        },
    }
</script>
<style lang="scss" scoped>

    .navbar{
        z-index:100;

        .navbar-toggler{
            z-index:100;
            border: none;
        }

        .navbar-collapse{
            background-color: #456FEF;
            margin-left: -15px;
            margin-right: -15px;
            height: 100vh;
            margin-top: -49px;

            &.show{
                display: flex !important;
                justify-content: center !important;
                    align-items: center !important;
            }

            .navbar-nav{

                font-weight: 400;
                font-size: 30px;
                text-align: center;
                line-height: 60px;

                .nav-item{
                    a{
                        color:#FFF;
                    }
                }

            }
        }
    }

    .index-header{
        margin-top: -60px;
        background: url('../assets/index-header-xs.jpg');
        margin-bottom:0;
        padding-bottom:85px;
        background-size: cover;

        @media(min-width: 768px) {
            background: url('../assets/index-header.jpg');
        }

        h1 {
            margin-top: 130px;
            font-size: 50px;
            letter-spacing: 1.25px;
            line-height: 55px;
        }

        p{
            font-size: 21px;
            letter-spacing: 0.63px;
            line-height: 28px;
            margin-bottom:30px;
        }

        .header{
            padding-top:60px;
        }
    }

    .claim{
        font-size: 15px;
        letter-spacing: 0.72px;
        background-color: #456FEF;
        color:#FFF;
        font-weight:600;
        text-transform: uppercase;

        p{
            margin-top:15px;
            margin-bottom:15px;
        }
    }

    .seperator{
        margin-bottom:45px;
    }

    .button{
        margin:20px 0
    }

</styles>