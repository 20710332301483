<template>
    <div>
        <img class="img-fluid smart-header" src="../assets/smart-header-xs.jpg" />
        <div class="container smart-container">
            <div class="row">
                <h3 class="col-10 offset-1 text-center">Wir können noch mehr mit Smart Repair.</h3>
                <div class="col-md-6 offset-md-3">
                    <p class="demi">Unser Leistungspaket umfasst weitere Angebote, für die eine individuelle Begutachtung nötig ist. Vereinbaren Sie unverbindlich einen Termin bei uns vor Ort und lassen Sie sich beraten.</p>
                </div>
                <div class="col-12">
                    <ul class="list-unstyled">
                        <li>+ Entfernung von Steinschlägen</li>
                        <li>+ Entfernung von Schrammen</li>
                        <li>+ Entfernung von Dellen</li>
                        <li>+ Beilackieren</li>
                        <li>+ Stoffreparatur</li>
                        <li>+ Lederreparatur</li>
                        <li>+ Smart Repair</li>
                    </ul>
                </div>
                <div class="col-12 text-center" v-if="!bookingVisible">
                    <a @click.prevent="openBooking" class="button">Termin vereinbahren</a>
                </div>

                <div class="col-12 booking-field" v-if="bookingVisible">
                    <p>Hinterlassen Sie Ihre Telefonnummer und wir melden uns in spätestens 24 Stunden.</p>
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" placeholder="Telefonnummer" aria-label="Telefonnummer">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary button" type="button" id="send-phone">Absenden</button>
                        </div>
                    </div>
                    <p>Oder rufen Sie uns an, unter 0351 285 79 285</p>
                </div>

                <div class="col-12 grey-bg google-row">
                    <h4 class="col-md-12 text-center">Bewertungen auf Google</h4>
                    <GoogleReviews slidesPerView="1"></GoogleReviews>
                </div>
            </div>
        </div>
        <footer>

            <div class="container-fluid red-bg">
                <div class="row">

                    <div class="col-12 text-center">
                        <h4>Geschenkidee gesucht?</h4>
                    </div>

                    <div class="col-12 text-center">
                        <router-link to="coupons"><a class="button white">Gutschein buchen</a></router-link>
                    </div>

                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-6">

                    <img class="img-fluid" src="../assets/logo.svg">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2 offset-md-2">
                        <h5>Kontakt</h5>
                        <ul class="list-unstyled">
                            <li>Dohnaer Straße 310</li>
                            <li>01257 Dresden</li>
                            <li>Telefon 0351 285 792 85</li>
                            <li><a class="mail" href="mailto:info@waschprofis.de">info@waschprofis.de</a></li>
                        </ul>
                        <ul class="list-unstyled">
                            <li><a target="_blank" href="https://www.google.de/maps/dir//Dohnaer+Str.+310,+01257+Dresden/@50.987405,13.8093994,16.25z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4709c707dcabf051:0x18d4ff98cc55a5da!2m2!1d13.8122636!2d50.9885398!3e0">
                                <svg data-v-07dd0b06="" viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="geo alt fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-geo-alt-fill b-icon bi" style="font-size: 200%;"><g data-v-07dd0b06=""><path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"></path></g></svg><span class="direction">Anfahrt</span></a></li>
                        </ul>
                    </div>
                    <div class="col-md-2 offset-md-1">
                        <h5>Öffnungszeiten</h5>
                        <ul class="list-unstyled">
                            <li>Mo–Fr 07:00–19:00 Uhr</li>
                        </ul>
                    </div>
                    <div class="col-6 col-md-2 offset-md-1">
                        <h5>Unternehmen</h5>
                        <ul class="list-unstyled">
                            <li><router-link to="about" data-title="Über uns"><span>Über uns</span></router-link></li>
                            <li><router-link to="offer" data-title="Stellenangebote"><span>Stellenangebote</span></router-link></li>
                            <li><router-link to="coupons" data-title="Gutscheine"><span>Gutscheine</span></router-link></li>
                            <li><router-link to="/" data-title="Buchung"><span>Buchung</span></router-link></li>
                        </ul>
                    </div>
                    <div class="col-6 col-md-2">
                        <img class="seal" src="../assets/seal.svg" />
                    </div>
                </div>
                <div class="row sub-footer">
                    <div class="col-6 offset-3 text-center">
                        © 2021 Waschprofis
                    </div>
                    <div class="col-10 offset-1 text-center sub-footer__menu">

                        <ul class="list-unstyled list-inline">
                            <li class="list-inline-item"><router-link to="terms"><span>AGB</span></router-link></li>
                            <li class="list-inline-item"><router-link to="privacy"><span>Datenschutz</span></router-link></li>
                            <li class="list-inline-item"><router-link to="imprint"><span>Impressum</span></router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
    import GoogleReviews from "@/components/GoogleReviews.vue";

    export default {
        name: 'Footer',
        components:{
            GoogleReviews,
        },
        data() {
            return {
                bookingVisible: false,
            }
        },
        methods: {

            openBooking() {
                this.bookingVisible = true;
            }

        }
    }
</script>
<style lang="scss" scoped>

    .smart-header{
        margin-top: 75px;
    }

    .smart-container{
        margin-top:45px;
        padding-bottom:55px;

        h3{
            font-family: 'futura-pt-bold';
            font-size: 28px;
            letter-spacing: .7px;
            line-height:35px;
            margin-bottom:35px;
        }

        p{
            font-size: 17px;
            letter-spacing: 0.42px;
            line-height:24px;
            margin-bottom:25px;
        }

        ul{
            font-size: 17px;
            letter-spacing: 0.42px;
            line-height:24px;
            margin-bottom:30px;
        }
    }

    .booking-field{
        margin-top: 20px;

        p{
            margin-bottom:15px;
        }
    }

    .red-bg{
        padding:45px 15px;
        h4{
            font-size:20px;
            letter-spacing: 0.5px;
            line-height: 65px;
        }
    }

    .google-row{
        margin-top: 35px;
        padding: 50px 25px;

        h4{
            font-size: 28px;
            letter-spacing: 1px;
            line-height: 1.625em;
            margin-bottom: 1.625em;
        }
    }

    footer{
        h5{
            font-size:18px;
            letter-spacing: 0;
            line-height:25px;
        }

        .seal{
            width:80%;
            margin-bottom:40px;
        }

        .red-bg{
            margin-bottom:50px;
        }

        .img-fluid{
            margin-bottom:40px;
        }

        ul{
            margin-bottom: 2rem;
            li{
                font-size:18px;
                line-height: 25px;
            }
        }

        .direction{
            display: inline-block;
            margin-left:1rem;
        }

        .sub-footer{
            font-size:15px;
            line-height: 20px;

            &__menu{
                font-size:17px;
                margin-top:5px;
                padding-top:5px;
                border-top: thin solid #edeef0
            }

        }
    }

</styles>